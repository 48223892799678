/* Default margins are removed */
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

/* Images are block-level */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

/* Images are constrained to the parent width */
img,
video {
  max-width: 100%;
  height: auto;
}

html {
  scroll-behavior: auto;
}

a {
  text-decoration: none;
}
