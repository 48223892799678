@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .nsw-input-outline {
    outline: solid 3px var(--nsw-focus) !important;
    outline-offset: 3px !important;
    border-radius: var(--nsw-border-radius) !important;
  }
}
